// Gatsby requirements
import React from "react"
import { graphql } from "gatsby"

// Storyblok preview
import { storyblokInit, useStoryblokState } from "gatsby-source-storyblok"

// Components
import Popup from "components/Popup/Popup"


storyblokInit({
    accessToken: process.env.GATSBY_STORYBLOK_ACCESS_TOKEN,
});

const HeaderPreview = ({ data }) =>  {

    let story = useStoryblokState(data.storyblokEntry);

    return (
        <div>
            <div style={{height: '100vh', backgroundColor: '#fff', width: '100%', display: 'block'}} />
            <Popup previewData={story} />
        </div>
    )
}

export const Head = () => (
    <>
      <title>Popup Preview</title>
      <meta name="robots" content="noindex"/>
    </>
)

export const query = graphql`
    query {
        storyblokEntry(full_slug: { eq: "site-configuration/popup" }) {
            content
            id
            uuid
            internalId
        }
    }
`

export default HeaderPreview